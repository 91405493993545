import { StyledCard, StyledImage, StyledPre, StyledRoot } from "./styles";
import { BannerFrameProps } from "./types";

/**
 * @description A frame displaying an embedable banner.
 *
 * API:
 *
 * - inherits [Box API](https://material-ui.com/api/box/)
 */
const BannerFrame = ({
  sx,
  classes,
  href,
  width,
  imageName,
}: BannerFrameProps) => {
  // const defaultSrc = `https://www.strix.sowa.pl/static/img/banners/opac-${width}x${height}${outlined ? "-f" : ""}.jpg`
  // const defaultSrc = `${window.location.origin}/assets/media/sowa_mobi_media.png`;
  const baseUrl = window.location.origin;
  const src = `${baseUrl}/assets/media/${imageName}.png`;
  // const height = frameSize[size][1];

  return (
    <StyledRoot
      alignItems="center"
      sx={sx}
      className={classes?.root}
      style={{ width: "100%", maxWidth: width + "px" }}
    >
      <a href={src} download>
        <StyledImage
          src={src}
          alt="banner"
          style={{ width: "100%", maxWidth: width + "px" }}
        />
      </a>

      <StyledCard variant="outlined">
        <StyledPre>
          <code>{createEmbedCode(href, src)}</code>
        </StyledPre>
      </StyledCard>
    </StyledRoot>
  );
};

const createEmbedCode = (href: string, src: string) =>
  `<a href="${href}" target="_blank">
  <img src="${src}" />
</a>`;

export default BannerFrame;
