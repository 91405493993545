// Dependencies scoped imports
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

// Project scoped imports
import BannerFrame from "../BannerFrame";
import { BannerFrameSizes } from "../BannerFrame/types";

// Module scoped imports
import { useTrans } from "./trans";
import { StyledRoot, StyledSloganTypography } from "./styles";
import { BannersDialogProps } from "./types";
import { SOWA_BANNER_FRAME_SIZE } from "../BannerFrame/constants";

/**
 * @description A dialog displaying embedable banners.
 *
 * API:
 *
 * - inherits [Dialog API](https://material-ui.com/api/dialog/)
 */
const BannersDialog = ({ sx, classes }: BannersDialogProps) => {
  let navigate = useNavigate();
  const translated = useTrans();

  const handleClose = () => {
    navigate("/");
  };

  return (
    <>
      <StyledRoot
        maxWidth="xl"
        fullWidth
        open
        onClose={handleClose}
        sx={sx}
        className={classes?.root}
      >
        <DialogTitle>
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {translated.title}
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>

        <DialogContent>
          <StyledSloganTypography>
            {translated.description}
          </StyledSloganTypography>
          <br />
          <br />
          <br />

          <Stack spacing={3} divider={<Divider />}>
            <BannerTandem
              title="Kod QR w rozdzielczości"
              imageNames={["qr/qr2_media"]}
              width={276}
              height={150}
            />
            <BannerTandem
              title="Kody QR w rozdzielczości"
              imageNames={["qr/qr1_media", "qr/qr1_alt_media"]}
              width={150}
              height={150}
            />
            <BannerTandem
              title="Kod QR w rozdzielczości"
              imageNames={["qr/qr2@2x_media"]}
              width={552}
              height={300}
            />
            <BannerTandem
              title="Kody QR w rozdzielczości"
              imageNames={["qr/qr1@2x_media", "qr/qr1_alt@2x_media"]}
              width={300}
              height={300}
            />
            <BannerTandem
              title="Banery MOBI w rozdzielczości"
              imageNames={["opac-150x40", "opac-150x40_alt"]}
              width={150}
              height={40}
            />
            <BannerTandem
              title="Banery MOBI w rozdzielczości"
              imageNames={["opac-150x40@2x", "opac-150x40_alt@2x"]}
              width={300}
              height={80}
            />
            <BannerTandem
              title="Banery MOBI w rozdzielczości"
              imageNames={["opac-250x60", "opac-250x60_alt"]}
              width={250}
              height={60}
            />
            <BannerTandem
              title="Banery MOBI w rozdzielczości"
              imageNames={["opac-250x60@2x", "opac-250x60_alt@2x"]}
              width={500}
              height={120}
            />
            <BannerTandem
              title="Banery MOBI w rozdzielczości"
              imageNames={["opac-150x200", "opac-150x200_alt"]}
              width={150}
              height={200}
            />
            <BannerTandem
              title="Banery MOBI w rozdzielczości"
              imageNames={["opac-150x200@2x", "opac-150x200_alt@2x"]}
              width={300}
              height={400}
            />
            <BannerTandem
              title="Baner MOBI w rozdzielczości"
              imageNames={["mobi-755x200"]}
              width={755}
              height={200}
            />
            <BannerTandem
              title="Baner MOBI w rozdzielczości"
              imageNames={["mobi-755x200@2x"]}
              width={1511}
              height={400}
            />
            <BannerFrameTandem size="xs" />
            <BannerFrameTandem size="sm" />
            <BannerFrameTandem size="md" />
            <BannerFrameTandem size="lg" />
          </Stack>
        </DialogContent>
      </StyledRoot>
    </>
  );
};

const BannerFrameTandem = ({ size }: { size: BannerFrameSizes }) => {
  const translated = useTrans();

  return (
    <BannerTandem
      title={translated.resolution}
      imageNames={[`${size}_media`, `${size}_f_media`]}
      width={SOWA_BANNER_FRAME_SIZE[size][0]}
      height={SOWA_BANNER_FRAME_SIZE[size][1]}
    />
  );
};

function BannerTandem({
  title,
  imageNames,
  width,
  height,
}: {
  title: string;
  imageNames: string[];
  width: number;
  height: number;
}) {
  return (
    <Box marginY={3}>
      <Typography variant="h5" align="center" style={{ marginBottom: "12px" }}>
        {title} {width}x{height}
      </Typography>

      <Stack flexDirection="row" flexWrap="wrap" justifyContent="center">
        {imageNames.map((name) => (
          <BannerFrame
            key={name}
            imageName={name}
            href="https://mobi.sowa.pl/"
            width={width}
            height={height}
          />
        ))}
      </Stack>
    </Box>
  );
}

export default BannersDialog;
