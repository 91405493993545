/* External packages imports */
import React /*, { useState }*/ from "react";
import {
  Container,
  Hidden,
  Button,
  Stack,
  Typography /*, useMediaQuery*/,
} from "@mui/material";
import { Box } from "@mui/system";
import { Link } from "react-scroll";

/* Project scope imports. */
import splashImage from "assets/webp/previewed.webp";
import SplitSection from "../Split";
// import { useCheckWebmSupport } from "../../utils/webm";

/* Component scope imports. */
import { useTrans } from "./trans";
import {
  OrbitRing,
  /*ThumbnailVideo, */ ThumbnailImage,
  StyledTitle,
  StyledSlogan,
  StyledMobileTitleBox,
} from "./styles";

type HeroSectionProps = {
  sectionRef?: React.Ref<HTMLDivElement>;
};

const MuiHidden = Hidden as unknown as (
  props: Record<string, any>
) => JSX.Element;

const HeroSection = ({ sectionRef: ref }: HeroSectionProps) => {
  const translated = useTrans();

  // const webmSupported = useCheckWebmSupport();
  // const [animationEnabled, setAnimationEnabled] = useState(true);
  // const prefersReducedMotion = useMediaQuery("(prefers-reduced-motion: reduce)");

  // const disableAnimation = () => setAnimationEnabled(false);
  // const enableAnimation = () => setAnimationEnabled(webmSupported);

  return (
    <Box
      ref={ref}
      style={{
        position: "relative",
        overflow: "hidden",
        width: "100vw",
        maxWidth: "100%",
      }}
    >
      <SplitSection
        style={{
          height: "100%",
          minHeight: "90vh",
          display: "flex",
          alignItems: "center",
        }}
        RightBoxProps={{ alignSelf: "flex-end", justifySelf: "end" }}
        ContainerProps={{
          style: {
            maxWidth: "100%",
            padding: 0,
            marginLeft: 0,
            marginRight: 0,
          },
          disableGutters: true,
        }}
      >
        <Container
          maxWidth="xs"
          style={{ height: "100%", zIndex: 3, position: "relative" }}
        >
          <Stack
            height="100%"
            alignItems={{ xs: "center", sm: "center", md: "flex-start" }}
            justifyContent="center"
          >
            <MuiHidden mdDown>
              <Box>
                <StyledTitle variant="h2">{translated.title}</StyledTitle>
                <StyledSlogan paragraph>{translated.description}</StyledSlogan>
              </Box>
            </MuiHidden>
            <MuiHidden mdUp>
              <StyledMobileTitleBox>
                <StyledTitle
                  variant="h2"
                  style={{
                    fontSize: "2.1rem",
                    marginBottom: "14px",
                    lineHeight: "3.0rem",
                  }}
                >
                  {translated.title}
                </StyledTitle>
                <StyledSlogan paragraph style={{ fontSize: "16px" }}>
                  {translated.description}
                </StyledSlogan>
              </StyledMobileTitleBox>
            </MuiHidden>

            <Box marginTop="42px" width="100%">
              <Stack
                direction={{ sm: "row", xs: "column", md: "row" }}
                spacing={2}
              >
                {/* <Box width={{ xs: "100%", sm: "100%", md: "172px" }}>
                                <Link
                                    to="first_section"
                                    smooth={true}
                                    offset={-70}
                                    duration={500}>
                                    <Button size="large" variant="outlined" fullWidth color="primary" onClick={() => { }}>{translated.explore}</Button>
                                </Link>
                            </Box> */}
                <Box width={{ xs: "100%", sm: "100%", md: "222px" }}>
                  <Link
                    to="download_section"
                    smooth={true}
                    offset={-70}
                    duration={500}
                  >
                    <Button
                      size="large"
                      variant="contained"
                      fullWidth
                      color="primary"
                      onClick={() => {}}
                    >
                      {translated.download}
                    </Button>
                  </Link>
                  <Typography
                    style={{
                      opacity: 0.45,
                      fontSize: "13px",
                      textAlign: "center",
                      marginTop: "8px",
                    }}
                  >
                    {translated.freeDownload}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </Container>

        <>
          <MuiHidden lgDown>
            <Box position="relative" flex={1} height="100vh">
              {/* { !prefersReducedMotion && animationEnabled ?
                            <ThumbnailVideo onClick={disableAnimation} autoPlay={true} loop controls={false} muted>
                                <source src={splashVideo} type="video/webm" />
                            </ThumbnailVideo>
                            : 
                            <ThumbnailImage onClick={enableAnimation} src={splashImage} alt="Floating phones" />} */}

              <ThumbnailImage src={splashImage} alt="Floating phones" style={{ zIndex: -1 }}/>

              <OrbitRing size={389} opacity={0.5} />
              <OrbitRing size={666} opacity={0.25} />
              <OrbitRing size={936} opacity={0.15} />
              <OrbitRing size={1317} opacity={0.12} />
              <OrbitRing size={1617} opacity={0.09} />
              <OrbitRing size={1917} opacity={0.045} />
            </Box>
          </MuiHidden>

          <MuiHidden lgUp>
            <Box position="absolute" top="70%" right="-45%">
              <OrbitRing size={389} opacity={0.5} />
              <OrbitRing size={666} opacity={0.25} />
              <OrbitRing size={936} opacity={0.15} />
              <OrbitRing size={1317} opacity={0.12} />
              <OrbitRing size={1617} opacity={0.09} />
              <OrbitRing size={1917} opacity={0.045} />
            </Box>
          </MuiHidden>
        </>
      </SplitSection>
    </Box>
  );
};

export default HeroSection;
